import { graphql } from 'types/gql';

// BACKEND

graphql(`
  fragment BaseUserFields on User {
    id
    givenName
    familyName
    email
  }
`);

graphql(`
  fragment GroupsUsers on User {
    groupsUsers {
      nodes {
        groupId
      }
    }
  }
`);

graphql(`
  fragment GroupsUsersFull on User {
    groupsUsers {
      nodes {
        group {
          name
        }
      }
    }
  }
`);

graphql(`
  fragment UserAttributes on User {
    attributes
  }
`);

/*
  Backend [Query]
  Get all users, with pagination and ordering
*/
export const getUsersQuery = graphql(`
  query GetUsers($perPage: Int!, $offset: Int!, $order: [UsersOrderBy!]) {
    users(first: $perPage, offset: $offset, orderBy: $order) {
      totalCount
      nodes {
        ...BaseUserFields
        ...UserAttributes
        ...GroupsUsers
      }
    }
  }
`);

/*
  Backend [Query]
  Get all users for import, combination of email, id and group membership
*/
export const getAllUsersImportQuery = graphql(`
  query GetAllUsersImport($perPage: Int!, $offset: Int!) {
    users(first: $perPage, offset: $offset) {
      totalCount
      nodes {
        ...BaseUserFields
        ...UserAttributes
        ...GroupsUsersFull
      }
    }
  }
`);

/*
  Backend [Query]
  Get all users, with pagination, ordering and filtering
*/
export const getUsersFilteredQuery = graphql(`
  query GetUsersFiltered(
    $perPage: Int!
    $offset: Int!
    $order: [UsersOrderBy!]
    $filter: UserFilter
  ) {
    users(first: $perPage, offset: $offset, orderBy: $order, filter: $filter) {
      totalCount
      nodes {
        ...BaseUserFields
        ...UserAttributes
        ...GroupsUsers
      }
    }
  }
`);

/*
  Backend [Query]
  Get all users for one or more groups, specified in the filter, with pagination
*/
export const getUsersForGroupQuery = graphql(`
  query GetUsersInGroup(
    $perPage: Int!
    $offset: Int!
    $condition: GroupsUserCondition!
  ) {
    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {
      totalCount
      nodes {
        user {
          ...BaseUserFields
        }
      }
    }
  }
`);

/*
  Backend [Query]
  Get all catalogue items, grouped by group, for a user, specified in the filter
*/
export const getCatalogueItemsForUser = graphql(`
  query GetCatalogueItemsForUser(
    $perPage: Int!
    $offset: Int!
    $condition: GroupsUserCondition!
  ) {
    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {
      totalCount
      nodes {
        group {
          name
          catalogueItemsGroups {
            nodes {
              catalogueItemId
              course
            }
          }
        }
      }
    }
  }
`);

/*
  Backend [Mutation]
  Create a user
*/
export const createUser = graphql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`);

/*
  Backend [Mutation]
  Update a user
*/
export const updateUser = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
      }
    }
  }
`);

/*
  Backend [Mutation]
  Delete a user
*/
export const deleteUser = graphql(`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }
`);

/*
  Backend [Mutation]
  Send reminder email
*/
export const sendReminderEmail = graphql(`
  mutation SendReminderEmail($input: SendReminderEmailInput!) {
    sendReminderEmail(input: $input) {
      user {
        id
      }
    }
  }
`);
