/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

const documents = {
    "\n  query GetAttributes($filter: AttributeFilter) {\n    attributes(filter: $filter) {\n      nodes {\n        id\n        cognitoName\n        key\n        name\n        description\n        enabled\n        requiredImport\n      }\n      totalCount\n    }\n  }\n": types.GetAttributesDocument,
    "\n  mutation CreateAttribute($input: CreateAttributeInput!) {\n    createAttribute(input: $input) {\n      attribute {\n        id\n      }\n    }\n  }\n": types.CreateAttributeDocument,
    "\n  mutation UpdateAttribute($input: UpdateAttributeInput!) {\n    updateAttribute(input: $input) {\n      attribute {\n        id\n      }\n    }\n  }\n": types.UpdateAttributeDocument,
    "\n  query GetCatalogueItems(\n    $perPage: Int!\n    $offset: Int!\n    $order: [LearningConceptOrder]\n  ) {\n    learningConceptCollection(limit: $perPage, skip: $offset, order: $order) {\n      total\n      items {\n        id\n        type\n        title\n        description\n        sys {\n          id\n        }\n      }\n    }\n  }\n": types.GetCatalogueItemsDocument,
    "\n  query GetCatalogueItemsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $where: LearningConceptFilter!\n    $order: [LearningConceptOrder]\n  ) {\n    learningConceptCollection(\n      limit: $perPage\n      skip: $offset\n      where: $where\n      order: $order\n    ) {\n      total\n      items {\n        id\n        type\n        title\n        description\n        sys {\n          id\n        }\n      }\n    }\n  }\n": types.GetCatalogueItemsFilteredDocument,
    "\n  query GetCatalogueItem($id: String!) {\n    learningConcept(id: $id) {\n      id\n      type\n      title\n      description\n      sys {\n        id\n      }\n    }\n  }\n": types.GetCatalogueItemDocument,
    "\n  query GetCatalogueItemsGroupCounts($filter: CatalogueItemFilter!) {\n    catalogueItems(filter: $filter) {\n      nodes {\n        catalogueItemId\n        groupCount\n      }\n    }\n  }\n": types.GetCatalogueItemsGroupCountsDocument,
    "\n  query GetGroupsInCatalogueItem(\n    $perPage: Int!\n    $offset: Int!\n    $condition: CatalogueItemsGroupCondition!\n  ) {\n    catalogueItemsGroups(\n      first: $perPage\n      offset: $offset\n      condition: $condition\n    ) {\n      totalCount\n      nodes {\n        course\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n": types.GetGroupsInCatalogueItemDocument,
    "\n  query GetCatalogueItemsForGroup($condition: CatalogueItemsGroupCondition!) {\n    catalogueItemsGroups(condition: $condition) {\n      totalCount\n      nodes {\n        catalogueItemId\n      }\n    }\n  }\n": types.GetCatalogueItemsForGroupDocument,
    "\n  query GetCatalogueItemsForGroupFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $condition: CatalogueItemsGroupCondition!\n  ) {\n    catalogueItemsGroups(\n      first: $perPage\n      offset: $offset\n      condition: $condition\n    ) {\n      totalCount\n      nodes {\n        course\n        catalogueItemId\n      }\n    }\n  }\n": types.GetCatalogueItemsForGroupFilteredDocument,
    "\n  mutation AddGroupToCatalogueItem($input: CreateCatalogueItemsGroupInput!) {\n    createCatalogueItemsGroup(input: $input) {\n      catalogueItemsGroup {\n        catalogueItemId\n        groupId\n        course\n      }\n    }\n  }\n": types.AddGroupToCatalogueItemDocument,
    "\n  mutation RemoveGroupFromCatalogueItem(\n    $input: RemoveGroupFromCatalogueItemInput!\n  ) {\n    removeGroupFromCatalogueItem(input: $input) {\n      catalogueItemsGroup {\n        catalogueItemId\n        groupId\n      }\n    }\n  }\n": types.RemoveGroupFromCatalogueItemDocument,
    "\n  query getStats {\n    users {\n      totalCount\n    }\n    groups {\n      totalCount\n    }\n  }\n": types.GetStatsDocument,
    "\n  query GetGroups($perPage: Int!, $offset: Int!, $order: [GroupsOrderBy!]) {\n    groups(first: $perPage, offset: $offset, orderBy: $order) {\n      nodes {\n        name\n        description\n        groupsUsers {\n          totalCount\n        }\n        catalogueItemsGroups {\n          totalCount\n        }\n        tenantsGroups {\n          totalCount\n        }\n      }\n      totalCount\n    }\n  }\n": types.GetGroupsDocument,
    "\n  query GetAllGroupsImport {\n    groups {\n      nodes {\n        name\n      }\n      totalCount\n    }\n  }\n": types.GetAllGroupsImportDocument,
    "\n  query GetGroupsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $order: [GroupsOrderBy!]\n    $filter: GroupFilter!\n  ) {\n    groups(first: $perPage, offset: $offset, orderBy: $order, filter: $filter) {\n      nodes {\n        name\n        description\n        groupsUsers {\n          totalCount\n        }\n        catalogueItemsGroups {\n          totalCount\n        }\n        tenantsGroups {\n          totalCount\n        }\n      }\n      totalCount\n    }\n  }\n": types.GetGroupsFilteredDocument,
    "\n  query GetGroupsForUser(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n": types.GetGroupsForUserDocument,
    "\n  query GetUsersGroups($first: Int!, $after: String) {\n    groupsUsers(first: $first, after: $after) {\n      nodes {\n        user {\n          id\n        }\n        group {\n          name\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      totalCount\n    }\n  }\n": types.GetUsersGroupsDocument,
    "\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n": types.CreateGroupDocument,
    "\n  mutation UpdateGroup($input: UpdateGroupInput!) {\n    updateGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n": types.UpdateGroupDocument,
    "\n  mutation DeleteGroup($input: DeleteGroupInput!) {\n    deleteGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n": types.DeleteGroupDocument,
    "\n  mutation AddUserToGroup($input: CreateGroupsUserInput!) {\n    createGroupsUser(input: $input) {\n      groupsUser {\n        groupId\n        userId\n      }\n    }\n  }\n": types.AddUserToGroupDocument,
    "\n  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {\n    removeUserFromGroup(input: $input) {\n      groupsUser {\n        groupId\n        userId\n      }\n    }\n  }\n": types.RemoveUserFromGroupDocument,
    "\n  query GetTenants($perPage: Int!, $offset: Int!, $order: [AcademyOrder]) {\n    academyCollection(limit: $perPage, skip: $offset, order: $order) {\n      total\n      items {\n        academyId\n        academyTitle\n        isPrimary\n        sys {\n          id\n        }\n      }\n    }\n  }\n": types.GetTenantsDocument,
    "\n  query GetTenantsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $where: AcademyFilter!\n    $order: [AcademyOrder]\n  ) {\n    academyCollection(\n      limit: $perPage\n      skip: $offset\n      where: $where\n      order: $order\n    ) {\n      total\n      items {\n        academyId\n        academyTitle\n        isPrimary\n        sys {\n          id\n        }\n      }\n    }\n  }\n": types.GetTenantsFilteredDocument,
    "\n  query GetTenant($id: String!) {\n    academy(id: $id) {\n      academyId\n      academyTitle\n      isPrimary\n      sys {\n        id\n      }\n    }\n  }\n": types.GetTenantDocument,
    "\n  query GetTenantsGroupCounts($filter: TenantFilter!) {\n    tenants(filter: $filter) {\n      nodes {\n        tenantId\n        tenantInternalId\n        groupCount\n      }\n    }\n  }\n": types.GetTenantsGroupCountsDocument,
    "\n  query GetGroupsInTenant(\n    $perPage: Int!\n    $offset: Int!\n    $condition: TenantsGroupCondition!\n  ) {\n    tenantsGroups(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        course\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n": types.GetGroupsInTenantDocument,
    "\n  query GetTenantsForGroup($condition: TenantsGroupCondition!) {\n    tenantsGroups(condition: $condition) {\n      totalCount\n      nodes {\n        tenantId\n        tenantInternalId\n      }\n    }\n  }\n": types.GetTenantsForGroupDocument,
    "\n  query GetTenantsForGroupFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $condition: TenantsGroupCondition!\n  ) {\n    tenantsGroups(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        course\n        tenantId\n        tenantInternalId\n      }\n    }\n  }\n": types.GetTenantsForGroupFilteredDocument,
    "\n  mutation AddGroupToTenant($input: CreateTenantsGroupInput!) {\n    createTenantsGroup(input: $input) {\n      tenantsGroup {\n        tenantId\n        tenantInternalId\n        groupId\n        course\n      }\n    }\n  }\n": types.AddGroupToTenantDocument,
    "\n  mutation RemoveGroupFromTenant($input: RemoveGroupFromTenantInput!) {\n    removeGroupFromTenant(input: $input) {\n      tenantsGroup {\n        tenantInternalId\n        groupId\n      }\n    }\n  }\n": types.RemoveGroupFromTenantDocument,
    "\n  fragment BaseUserFields on User {\n    id\n    givenName\n    familyName\n    email\n  }\n": types.BaseUserFieldsFragmentDoc,
    "\n  fragment GroupsUsers on User {\n    groupsUsers {\n      nodes {\n        groupId\n      }\n    }\n  }\n": types.GroupsUsersFragmentDoc,
    "\n  fragment GroupsUsersFull on User {\n    groupsUsers {\n      nodes {\n        group {\n          name\n        }\n      }\n    }\n  }\n": types.GroupsUsersFullFragmentDoc,
    "\n  fragment UserAttributes on User {\n    attributes\n  }\n": types.UserAttributesFragmentDoc,
    "\n  query GetUsers($perPage: Int!, $offset: Int!, $order: [UsersOrderBy!]) {\n    users(first: $perPage, offset: $offset, orderBy: $order) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsers\n      }\n    }\n  }\n": types.GetUsersDocument,
    "\n  query GetAllUsersImport($perPage: Int!, $offset: Int!) {\n    users(first: $perPage, offset: $offset) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsersFull\n      }\n    }\n  }\n": types.GetAllUsersImportDocument,
    "\n  query GetUsersFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $order: [UsersOrderBy!]\n    $filter: UserFilter\n  ) {\n    users(first: $perPage, offset: $offset, orderBy: $order, filter: $filter) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsers\n      }\n    }\n  }\n": types.GetUsersFilteredDocument,
    "\n  query GetUsersInGroup(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        user {\n          ...BaseUserFields\n        }\n      }\n    }\n  }\n": types.GetUsersInGroupDocument,
    "\n  query GetCatalogueItemsForUser(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        group {\n          name\n          catalogueItemsGroups {\n            nodes {\n              catalogueItemId\n              course\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetCatalogueItemsForUserDocument,
    "\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.CreateUserDocument,
    "\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation DeleteUser($input: DeleteUserInput!) {\n    deleteUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.DeleteUserDocument,
    "\n  mutation SendReminderEmail($input: SendReminderEmailInput!) {\n    sendReminderEmail(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.SendReminderEmailDocument,
};

export function graphql(source: "\n  query GetAttributes($filter: AttributeFilter) {\n    attributes(filter: $filter) {\n      nodes {\n        id\n        cognitoName\n        key\n        name\n        description\n        enabled\n        requiredImport\n      }\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query GetAttributes($filter: AttributeFilter) {\n    attributes(filter: $filter) {\n      nodes {\n        id\n        cognitoName\n        key\n        name\n        description\n        enabled\n        requiredImport\n      }\n      totalCount\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreateAttribute($input: CreateAttributeInput!) {\n    createAttribute(input: $input) {\n      attribute {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAttribute($input: CreateAttributeInput!) {\n    createAttribute(input: $input) {\n      attribute {\n        id\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation UpdateAttribute($input: UpdateAttributeInput!) {\n    updateAttribute(input: $input) {\n      attribute {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAttribute($input: UpdateAttributeInput!) {\n    updateAttribute(input: $input) {\n      attribute {\n        id\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetCatalogueItems(\n    $perPage: Int!\n    $offset: Int!\n    $order: [LearningConceptOrder]\n  ) {\n    learningConceptCollection(limit: $perPage, skip: $offset, order: $order) {\n      total\n      items {\n        id\n        type\n        title\n        description\n        sys {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCatalogueItems(\n    $perPage: Int!\n    $offset: Int!\n    $order: [LearningConceptOrder]\n  ) {\n    learningConceptCollection(limit: $perPage, skip: $offset, order: $order) {\n      total\n      items {\n        id\n        type\n        title\n        description\n        sys {\n          id\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetCatalogueItemsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $where: LearningConceptFilter!\n    $order: [LearningConceptOrder]\n  ) {\n    learningConceptCollection(\n      limit: $perPage\n      skip: $offset\n      where: $where\n      order: $order\n    ) {\n      total\n      items {\n        id\n        type\n        title\n        description\n        sys {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCatalogueItemsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $where: LearningConceptFilter!\n    $order: [LearningConceptOrder]\n  ) {\n    learningConceptCollection(\n      limit: $perPage\n      skip: $offset\n      where: $where\n      order: $order\n    ) {\n      total\n      items {\n        id\n        type\n        title\n        description\n        sys {\n          id\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetCatalogueItem($id: String!) {\n    learningConcept(id: $id) {\n      id\n      type\n      title\n      description\n      sys {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCatalogueItem($id: String!) {\n    learningConcept(id: $id) {\n      id\n      type\n      title\n      description\n      sys {\n        id\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetCatalogueItemsGroupCounts($filter: CatalogueItemFilter!) {\n    catalogueItems(filter: $filter) {\n      nodes {\n        catalogueItemId\n        groupCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCatalogueItemsGroupCounts($filter: CatalogueItemFilter!) {\n    catalogueItems(filter: $filter) {\n      nodes {\n        catalogueItemId\n        groupCount\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetGroupsInCatalogueItem(\n    $perPage: Int!\n    $offset: Int!\n    $condition: CatalogueItemsGroupCondition!\n  ) {\n    catalogueItemsGroups(\n      first: $perPage\n      offset: $offset\n      condition: $condition\n    ) {\n      totalCount\n      nodes {\n        course\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetGroupsInCatalogueItem(\n    $perPage: Int!\n    $offset: Int!\n    $condition: CatalogueItemsGroupCondition!\n  ) {\n    catalogueItemsGroups(\n      first: $perPage\n      offset: $offset\n      condition: $condition\n    ) {\n      totalCount\n      nodes {\n        course\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetCatalogueItemsForGroup($condition: CatalogueItemsGroupCondition!) {\n    catalogueItemsGroups(condition: $condition) {\n      totalCount\n      nodes {\n        catalogueItemId\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCatalogueItemsForGroup($condition: CatalogueItemsGroupCondition!) {\n    catalogueItemsGroups(condition: $condition) {\n      totalCount\n      nodes {\n        catalogueItemId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetCatalogueItemsForGroupFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $condition: CatalogueItemsGroupCondition!\n  ) {\n    catalogueItemsGroups(\n      first: $perPage\n      offset: $offset\n      condition: $condition\n    ) {\n      totalCount\n      nodes {\n        course\n        catalogueItemId\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCatalogueItemsForGroupFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $condition: CatalogueItemsGroupCondition!\n  ) {\n    catalogueItemsGroups(\n      first: $perPage\n      offset: $offset\n      condition: $condition\n    ) {\n      totalCount\n      nodes {\n        course\n        catalogueItemId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation AddGroupToCatalogueItem($input: CreateCatalogueItemsGroupInput!) {\n    createCatalogueItemsGroup(input: $input) {\n      catalogueItemsGroup {\n        catalogueItemId\n        groupId\n        course\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddGroupToCatalogueItem($input: CreateCatalogueItemsGroupInput!) {\n    createCatalogueItemsGroup(input: $input) {\n      catalogueItemsGroup {\n        catalogueItemId\n        groupId\n        course\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation RemoveGroupFromCatalogueItem(\n    $input: RemoveGroupFromCatalogueItemInput!\n  ) {\n    removeGroupFromCatalogueItem(input: $input) {\n      catalogueItemsGroup {\n        catalogueItemId\n        groupId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveGroupFromCatalogueItem(\n    $input: RemoveGroupFromCatalogueItemInput!\n  ) {\n    removeGroupFromCatalogueItem(input: $input) {\n      catalogueItemsGroup {\n        catalogueItemId\n        groupId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query getStats {\n    users {\n      totalCount\n    }\n    groups {\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query getStats {\n    users {\n      totalCount\n    }\n    groups {\n      totalCount\n    }\n  }\n"];
export function graphql(source: "\n  query GetGroups($perPage: Int!, $offset: Int!, $order: [GroupsOrderBy!]) {\n    groups(first: $perPage, offset: $offset, orderBy: $order) {\n      nodes {\n        name\n        description\n        groupsUsers {\n          totalCount\n        }\n        catalogueItemsGroups {\n          totalCount\n        }\n        tenantsGroups {\n          totalCount\n        }\n      }\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query GetGroups($perPage: Int!, $offset: Int!, $order: [GroupsOrderBy!]) {\n    groups(first: $perPage, offset: $offset, orderBy: $order) {\n      nodes {\n        name\n        description\n        groupsUsers {\n          totalCount\n        }\n        catalogueItemsGroups {\n          totalCount\n        }\n        tenantsGroups {\n          totalCount\n        }\n      }\n      totalCount\n    }\n  }\n"];
export function graphql(source: "\n  query GetAllGroupsImport {\n    groups {\n      nodes {\n        name\n      }\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query GetAllGroupsImport {\n    groups {\n      nodes {\n        name\n      }\n      totalCount\n    }\n  }\n"];
export function graphql(source: "\n  query GetGroupsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $order: [GroupsOrderBy!]\n    $filter: GroupFilter!\n  ) {\n    groups(first: $perPage, offset: $offset, orderBy: $order, filter: $filter) {\n      nodes {\n        name\n        description\n        groupsUsers {\n          totalCount\n        }\n        catalogueItemsGroups {\n          totalCount\n        }\n        tenantsGroups {\n          totalCount\n        }\n      }\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query GetGroupsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $order: [GroupsOrderBy!]\n    $filter: GroupFilter!\n  ) {\n    groups(first: $perPage, offset: $offset, orderBy: $order, filter: $filter) {\n      nodes {\n        name\n        description\n        groupsUsers {\n          totalCount\n        }\n        catalogueItemsGroups {\n          totalCount\n        }\n        tenantsGroups {\n          totalCount\n        }\n      }\n      totalCount\n    }\n  }\n"];
export function graphql(source: "\n  query GetGroupsForUser(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetGroupsForUser(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetUsersGroups($first: Int!, $after: String) {\n    groupsUsers(first: $first, after: $after) {\n      nodes {\n        user {\n          id\n        }\n        group {\n          name\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query GetUsersGroups($first: Int!, $after: String) {\n    groupsUsers(first: $first, after: $after) {\n      nodes {\n        user {\n          id\n        }\n        group {\n          name\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n      totalCount\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation UpdateGroup($input: UpdateGroupInput!) {\n    updateGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateGroup($input: UpdateGroupInput!) {\n    updateGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation DeleteGroup($input: DeleteGroupInput!) {\n    deleteGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteGroup($input: DeleteGroupInput!) {\n    deleteGroup(input: $input) {\n      group {\n        name\n        description\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation AddUserToGroup($input: CreateGroupsUserInput!) {\n    createGroupsUser(input: $input) {\n      groupsUser {\n        groupId\n        userId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddUserToGroup($input: CreateGroupsUserInput!) {\n    createGroupsUser(input: $input) {\n      groupsUser {\n        groupId\n        userId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {\n    removeUserFromGroup(input: $input) {\n      groupsUser {\n        groupId\n        userId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {\n    removeUserFromGroup(input: $input) {\n      groupsUser {\n        groupId\n        userId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTenants($perPage: Int!, $offset: Int!, $order: [AcademyOrder]) {\n    academyCollection(limit: $perPage, skip: $offset, order: $order) {\n      total\n      items {\n        academyId\n        academyTitle\n        isPrimary\n        sys {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTenants($perPage: Int!, $offset: Int!, $order: [AcademyOrder]) {\n    academyCollection(limit: $perPage, skip: $offset, order: $order) {\n      total\n      items {\n        academyId\n        academyTitle\n        isPrimary\n        sys {\n          id\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTenantsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $where: AcademyFilter!\n    $order: [AcademyOrder]\n  ) {\n    academyCollection(\n      limit: $perPage\n      skip: $offset\n      where: $where\n      order: $order\n    ) {\n      total\n      items {\n        academyId\n        academyTitle\n        isPrimary\n        sys {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTenantsFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $where: AcademyFilter!\n    $order: [AcademyOrder]\n  ) {\n    academyCollection(\n      limit: $perPage\n      skip: $offset\n      where: $where\n      order: $order\n    ) {\n      total\n      items {\n        academyId\n        academyTitle\n        isPrimary\n        sys {\n          id\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTenant($id: String!) {\n    academy(id: $id) {\n      academyId\n      academyTitle\n      isPrimary\n      sys {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTenant($id: String!) {\n    academy(id: $id) {\n      academyId\n      academyTitle\n      isPrimary\n      sys {\n        id\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTenantsGroupCounts($filter: TenantFilter!) {\n    tenants(filter: $filter) {\n      nodes {\n        tenantId\n        tenantInternalId\n        groupCount\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTenantsGroupCounts($filter: TenantFilter!) {\n    tenants(filter: $filter) {\n      nodes {\n        tenantId\n        tenantInternalId\n        groupCount\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetGroupsInTenant(\n    $perPage: Int!\n    $offset: Int!\n    $condition: TenantsGroupCondition!\n  ) {\n    tenantsGroups(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        course\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetGroupsInTenant(\n    $perPage: Int!\n    $offset: Int!\n    $condition: TenantsGroupCondition!\n  ) {\n    tenantsGroups(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        course\n        group {\n          name\n          description\n          groupsUsers {\n            totalCount\n          }\n          tenantsGroups {\n            totalCount\n          }\n          catalogueItemsGroups {\n            totalCount\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTenantsForGroup($condition: TenantsGroupCondition!) {\n    tenantsGroups(condition: $condition) {\n      totalCount\n      nodes {\n        tenantId\n        tenantInternalId\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTenantsForGroup($condition: TenantsGroupCondition!) {\n    tenantsGroups(condition: $condition) {\n      totalCount\n      nodes {\n        tenantId\n        tenantInternalId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetTenantsForGroupFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $condition: TenantsGroupCondition!\n  ) {\n    tenantsGroups(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        course\n        tenantId\n        tenantInternalId\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTenantsForGroupFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $condition: TenantsGroupCondition!\n  ) {\n    tenantsGroups(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        course\n        tenantId\n        tenantInternalId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation AddGroupToTenant($input: CreateTenantsGroupInput!) {\n    createTenantsGroup(input: $input) {\n      tenantsGroup {\n        tenantId\n        tenantInternalId\n        groupId\n        course\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AddGroupToTenant($input: CreateTenantsGroupInput!) {\n    createTenantsGroup(input: $input) {\n      tenantsGroup {\n        tenantId\n        tenantInternalId\n        groupId\n        course\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation RemoveGroupFromTenant($input: RemoveGroupFromTenantInput!) {\n    removeGroupFromTenant(input: $input) {\n      tenantsGroup {\n        tenantInternalId\n        groupId\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveGroupFromTenant($input: RemoveGroupFromTenantInput!) {\n    removeGroupFromTenant(input: $input) {\n      tenantsGroup {\n        tenantInternalId\n        groupId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment BaseUserFields on User {\n    id\n    givenName\n    familyName\n    email\n  }\n"): (typeof documents)["\n  fragment BaseUserFields on User {\n    id\n    givenName\n    familyName\n    email\n  }\n"];
export function graphql(source: "\n  fragment GroupsUsers on User {\n    groupsUsers {\n      nodes {\n        groupId\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment GroupsUsers on User {\n    groupsUsers {\n      nodes {\n        groupId\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment GroupsUsersFull on User {\n    groupsUsers {\n      nodes {\n        group {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment GroupsUsersFull on User {\n    groupsUsers {\n      nodes {\n        group {\n          name\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  fragment UserAttributes on User {\n    attributes\n  }\n"): (typeof documents)["\n  fragment UserAttributes on User {\n    attributes\n  }\n"];
export function graphql(source: "\n  query GetUsers($perPage: Int!, $offset: Int!, $order: [UsersOrderBy!]) {\n    users(first: $perPage, offset: $offset, orderBy: $order) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsers\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUsers($perPage: Int!, $offset: Int!, $order: [UsersOrderBy!]) {\n    users(first: $perPage, offset: $offset, orderBy: $order) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsers\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetAllUsersImport($perPage: Int!, $offset: Int!) {\n    users(first: $perPage, offset: $offset) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsersFull\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAllUsersImport($perPage: Int!, $offset: Int!) {\n    users(first: $perPage, offset: $offset) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsersFull\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetUsersFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $order: [UsersOrderBy!]\n    $filter: UserFilter\n  ) {\n    users(first: $perPage, offset: $offset, orderBy: $order, filter: $filter) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsers\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUsersFiltered(\n    $perPage: Int!\n    $offset: Int!\n    $order: [UsersOrderBy!]\n    $filter: UserFilter\n  ) {\n    users(first: $perPage, offset: $offset, orderBy: $order, filter: $filter) {\n      totalCount\n      nodes {\n        ...BaseUserFields\n        ...UserAttributes\n        ...GroupsUsers\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetUsersInGroup(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        user {\n          ...BaseUserFields\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetUsersInGroup(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        user {\n          ...BaseUserFields\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  query GetCatalogueItemsForUser(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        group {\n          name\n          catalogueItemsGroups {\n            nodes {\n              catalogueItemId\n              course\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCatalogueItemsForUser(\n    $perPage: Int!\n    $offset: Int!\n    $condition: GroupsUserCondition!\n  ) {\n    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {\n      totalCount\n      nodes {\n        group {\n          name\n          catalogueItemsGroups {\n            nodes {\n              catalogueItemId\n              course\n            }\n          }\n        }\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUser($input: UpdateUserInput!) {\n    updateUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation DeleteUser($input: DeleteUserInput!) {\n    deleteUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUser($input: DeleteUserInput!) {\n    deleteUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
export function graphql(source: "\n  mutation SendReminderEmail($input: SendReminderEmailInput!) {\n    sendReminderEmail(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation SendReminderEmail($input: SendReminderEmailInput!) {\n    sendReminderEmail(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];

export function graphql(source: string): unknown;
export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;