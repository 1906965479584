import { graphql } from 'types/gql';

// BACKEND

/*
  Backend [Query]
  Get all groups, with pagination and ordering
*/
export const getGroupsQuery = graphql(`
  query GetGroups($perPage: Int!, $offset: Int!, $order: [GroupsOrderBy!]) {
    groups(first: $perPage, offset: $offset, orderBy: $order) {
      nodes {
        name
        description
        groupsUsers {
          totalCount
        }
        catalogueItemsGroups {
          totalCount
        }
        tenantsGroups {
          totalCount
        }
      }
      totalCount
    }
  }
`);

/*
  Backend [Query]
  Get all groups for import, only name needed
*/
export const getAllGroupsImportQuery = graphql(`
  query GetAllGroupsImport {
    groups {
      nodes {
        name
      }
      totalCount
    }
  }
`);

/*
  Backend [Query]
  Get all groups, with pagination, ordering and filtering
*/
export const getGroupsFilteredQuery = graphql(`
  query GetGroupsFiltered(
    $perPage: Int!
    $offset: Int!
    $order: [GroupsOrderBy!]
    $filter: GroupFilter!
  ) {
    groups(first: $perPage, offset: $offset, orderBy: $order, filter: $filter) {
      nodes {
        name
        description
        groupsUsers {
          totalCount
        }
        catalogueItemsGroups {
          totalCount
        }
        tenantsGroups {
          totalCount
        }
      }
      totalCount
    }
  }
`);

/*
  Backend [Query]
  Get all groups for one or more users, specified in the filter, with pagination
*/
export const getGroupsForUserQuery = graphql(`
  query GetGroupsForUser(
    $perPage: Int!
    $offset: Int!
    $condition: GroupsUserCondition!
  ) {
    groupsUsers(first: $perPage, offset: $offset, condition: $condition) {
      totalCount
      nodes {
        group {
          name
          description
          groupsUsers {
            totalCount
          }
          catalogueItemsGroups {
            totalCount
          }
          tenantsGroups {
            totalCount
          }
        }
      }
    }
  }
`);

/*
  Backend [Query]
  Get all users for all groups with pagination
*/
export const getUsersGroupsQuery = graphql(`
  query GetUsersGroups($first: Int!, $after: String) {
    groupsUsers(first: $first, after: $after) {
      nodes {
        user {
          id
        }
        group {
          name
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`);

/*
  Backend [Mutation]
  Create a group
*/
export const createGroup = graphql(`
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      group {
        name
        description
      }
    }
  }
`);

/*
  Backend [Mutation]
  Update a group
*/
export const updateGroup = graphql(`
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      group {
        name
        description
      }
    }
  }
`);

/*
  Backend [Mutation]
  Delete a group
*/
export const deleteGroup = graphql(`
  mutation DeleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      group {
        name
        description
      }
    }
  }
`);

/*
  Backend [Mutation]
  Add a user to a group
*/
export const addUserToGroup = graphql(`
  mutation AddUserToGroup($input: CreateGroupsUserInput!) {
    createGroupsUser(input: $input) {
      groupsUser {
        groupId
        userId
      }
    }
  }
`);

/*
  Backend [Mutation]
  Remove a user from a group
*/
export const removeUserFromGroup = graphql(`
  mutation RemoveUserFromGroup($input: RemoveUserFromGroupInput!) {
    removeUserFromGroup(input: $input) {
      groupsUser {
        groupId
        userId
      }
    }
  }
`);
