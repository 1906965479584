type HandleReturnErrorArgs = {
  error: any;
  title: string;
  message: string;
};
export const handleReturnError = ({
  error,
  title,
  message,
}: HandleReturnErrorArgs) => {
  console.error(title, error);
  if (error?.name === 'NotAuthorizedException') {
    return Promise.reject('You are not authorized to perform this operation');
  } else {
    return Promise.reject(message);
  }
};
