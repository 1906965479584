import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { Login } from 'react-admin';
import { CardHeader, CardContent, Typography } from '@mui/material';
import { Auth } from '@aws-amplify/auth';

const LoginButton = () => {
  return (
    <CardActions style={{ justifyContent: 'center' }}>
      <Button
        onClick={() => Auth.federatedSignIn()}
        variant='contained'
        type='submit'
        color='primary'>
        Log in
      </Button>
    </CardActions>
  );
};

const MyLoginPage = () => {
  return (
    <Login>
      <CardHeader
        title={'Komensky User Management'}
        titleTypographyProps={{ align: 'center' }}
      />
      <CardContent>
        <Typography align='center'>
          Welcome to the Komensky User Management portal.
        </Typography>
        <Typography align='center'>
          Please login via Cognito by clicking the button below.
        </Typography>
      </CardContent>
      <LoginButton />
    </Login>
  );
};

export default MyLoginPage;
