import { SSMClient, GetParameterCommand } from '@aws-sdk/client-ssm';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { awsCredentials } from '../../authentication/credentials';
import { awsRegion, ssmEnv } from './env';

let contentfulApolloClient:
  | Promise<InstanceType<typeof ApolloClient>>
  | undefined = undefined;

type ContentfulConfig = {
  space: string;
  environment: string;
  accessToken: string;
  previewToken?: string;
};

export type ContentfulItem<T> = {
  sys: {
    id: string;
    [sysField: string]: any;
  };
} & T;

export type ContentfulResponse<CollectionName extends string, T> = {
  [C in CollectionName]: {
    total: number;
    items: T[];
  };
};

export type ContentfulOrder<ContentType> = keyof ({
  [Field in keyof ContentType as `${Extract<Field, string>}_ASC`]: undefined;
} & {
  [Field in keyof ContentType as `${Extract<Field, string>}_DESC`]: undefined;
});

type ContentfulFilter<ContentType> = {
  [P in keyof ContentType]?: ContentType[P] | any;
} & {
  OR?: ContentfulFilter<ContentType>[];
  AND?: ContentfulFilter<ContentType>[];
};

export type ContentfulArgs<ContentType> = {
  id?: string;
  perPage?: number;
  offset?: number;
  order?: ContentfulOrder<ContentType>[];
  where?: ContentfulFilter<ContentType>;
};

const contentfulGraphQLUrl = async () => {
  const config = await contentfulConfig();
  return `https://graphql.contentful.com/content/v1/spaces/${config.space}/environments/${config.environment}?access_token=${config.accessToken}`;
};

const parseList = (keys: string[], value: string) => {
  const list = value.split(',');
  const result: Record<string, string> = {};
  for (let i = 0; i < list.length && i < keys.length; i += 1) {
    result[keys[i]] = list[i];
  }
  return result;
};

const contentfulConfig = async () => {
  const client = new SSMClient({
    region: awsRegion,
    credentials: awsCredentials,
  });
  const command = new GetParameterCommand({
    Name: `academy-${ssmEnv}-contentful`,
    WithDecryption: false,
  });
  const response = await client.send(command);
  const parsed = parseList(
    ['space', 'environment', 'accessToken', 'previewToken'],
    response.Parameter!.Value!
  );
  return parsed as ContentfulConfig;
};

const makeContentfulClient = async () => {
  return new ApolloClient({
    uri: await contentfulGraphQLUrl(),
    cache: new InMemoryCache(),
  });
};

export const getContentfulClient = async () => {
  if (!contentfulApolloClient) {
    contentfulApolloClient = makeContentfulClient();
  }
  return contentfulApolloClient;
};
