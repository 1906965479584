import { graphql } from 'types/gql';

// CONTENTFUL

/*
  Contentful [Query]
  Get all catalogue items, with pagination and ordering
*/
export const getCatalogueItems = graphql(`
  query GetCatalogueItems(
    $perPage: Int!
    $offset: Int!
    $order: [LearningConceptOrder]
  ) {
    learningConceptCollection(limit: $perPage, skip: $offset, order: $order) {
      total
      items {
        id
        type
        title
        description
        sys {
          id
        }
      }
    }
  }
`);

/*
  Contentful [Query]
  Get all catalogue items, with pagination, ordering and filtering
*/
export const getCatalogueItemsFiltered = graphql(`
  query GetCatalogueItemsFiltered(
    $perPage: Int!
    $offset: Int!
    $where: LearningConceptFilter!
    $order: [LearningConceptOrder]
  ) {
    learningConceptCollection(
      limit: $perPage
      skip: $offset
      where: $where
      order: $order
    ) {
      total
      items {
        id
        type
        title
        description
        sys {
          id
        }
      }
    }
  }
`);

/*
  Contentful [Query]
  Get a single catalogue item by id
*/
export const getCatalogueItem = graphql(`
  query GetCatalogueItem($id: String!) {
    learningConcept(id: $id) {
      id
      type
      title
      description
      sys {
        id
      }
    }
  }
`);

// BACKEND

/*
  Backend [Query]
  Get all catalogue items with group counts
*/
export const getCatalogueItemsGroupCounts = graphql(`
  query GetCatalogueItemsGroupCounts($filter: CatalogueItemFilter!) {
    catalogueItems(filter: $filter) {
      nodes {
        catalogueItemId
        groupCount
      }
    }
  }
`);

/*
  Backend [Query]
  Get all groups for one or more catalogue items, specified in the filter, with pagination
*/
export const getGroupsForCatalogueItemQuery = graphql(`
  query GetGroupsInCatalogueItem(
    $perPage: Int!
    $offset: Int!
    $condition: CatalogueItemsGroupCondition!
  ) {
    catalogueItemsGroups(
      first: $perPage
      offset: $offset
      condition: $condition
    ) {
      totalCount
      nodes {
        course
        group {
          name
          description
          groupsUsers {
            totalCount
          }
          catalogueItemsGroups {
            totalCount
          }
          tenantsGroups {
            totalCount
          }
        }
      }
    }
  }
`);

/*
  Backend [Query]
  Get all catalogue items for one or more groups, specified in the filter
*/
export const getCatalogueItemsForGroup = graphql(`
  query GetCatalogueItemsForGroup($condition: CatalogueItemsGroupCondition!) {
    catalogueItemsGroups(condition: $condition) {
      totalCount
      nodes {
        catalogueItemId
      }
    }
  }
`);

/*
  Backend [Query]
  Get all catalogue items for one or more groups, specified in the filter, with pagination
*/
export const getCatalogueItemsForGroupFiltered = graphql(`
  query GetCatalogueItemsForGroupFiltered(
    $perPage: Int!
    $offset: Int!
    $condition: CatalogueItemsGroupCondition!
  ) {
    catalogueItemsGroups(
      first: $perPage
      offset: $offset
      condition: $condition
    ) {
      totalCount
      nodes {
        course
        catalogueItemId
      }
    }
  }
`);

/*
  Backend [Mutation]
  Add a group to a catalogue item
*/
export const addGroupToCatalogueItem = graphql(`
  mutation AddGroupToCatalogueItem($input: CreateCatalogueItemsGroupInput!) {
    createCatalogueItemsGroup(input: $input) {
      catalogueItemsGroup {
        catalogueItemId
        groupId
        course
      }
    }
  }
`);

/*
  Backend [Mutation]
  Remove a group from a catalogue item
*/
export const removeGroupFromCatalogueItem = graphql(`
  mutation RemoveGroupFromCatalogueItem(
    $input: RemoveGroupFromCatalogueItemInput!
  ) {
    removeGroupFromCatalogueItem(input: $input) {
      catalogueItemsGroup {
        catalogueItemId
        groupId
      }
    }
  }
`);
